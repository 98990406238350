import * as React from "react"
import Layout from "../../../components/Layout"
import { Row, Col } from "react-bootstrap"
import { Link } from "gatsby"

const IndexPage = () => {
    return (
        <Layout pageTitle="Awards - Press Releases">
            <Row>
                <Col>
                    <Link to="/awards/sme-awards">Back</Link>
                </Col>
            </Row>
            <Row className="mt-2">
                <Col md={8}></Col>
            </Row>
            <ValueRow
                name="Alternate Quotes from Angela"
                href="https://docs.google.com/document/d/18hstbx2CzE04O-bELphZBFysoWDXm4u-zqS7q1l2ZGE/edit?usp=sharing"
            />
            <ValueRow
                name="Awards Announcement"
                href="https://docs.google.com/document/d/1gmq-1efZgRNDDapGq4eyNwPPJPXD8th5CG0vz57MsVw/edit?usp=sharing"
            />
            <ValueRow name="Press Invite" 
            href="https://docs.google.com/document/d/1V99K2H2kjU1EvEkXho5heYXDARsJ5at12hVTm6LxJMo/edit?usp=sharing" 
            />
            <ValueRow
                name="Award Winners"
                href="https://docs.google.com/document/d/1CmcSHi2dOtwNwG45YZYLG9NFMpGbX1_H/edit?usp=sharing&ouid=104574001343036353924&rtpof=true&sd=true"
            />
        </Layout>
    )
}

const ValueRow = ({ name, href }) => {
    return (
        <Row className="mt-5">
            <Col>
                <h3>{name}</h3>
                <a
                    href={href}
                    className="btn btn-primary m-3"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                    Download Document
                </a>
            </Col>
        </Row>
    )
}

export default IndexPage
